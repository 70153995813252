








































































































import ErrorsBadge from "@/components/ErrorsBadge.vue";
import AddItemButton from "@/components/explore/AddItemButton.vue";
import DynamicResolver from "@/components/explore/DynamicResolver.vue";
import EditItemButton from "@/components/explore/EditItemButton.vue";
import ElementFieldList from "@/components/explore/ElementFieldList.vue";
import IndentedEntry from "@/components/explore/IndentedEntry.vue";
import { useElementErrors } from "@/components/explore/renderer/setup/element-errors";
import { useErrorHighlighting } from "@/components/explore/renderer/setup/error-highlight";
import SystemTypeRenderer from "@/components/explore/renderer/SystemTypeRenderer.vue";
import { determineRenderingOptions } from "@/components/explore/support/summary";
import {
  AddableFields,
  ElementWrapper,
  getSliceName,
  SlicedElementDefinition,
} from "@/lib-on-fhir";
import { config } from "@/services/config";
import { computed, defineComponent, onMounted, ref, watch } from "vue-demi";

export default defineComponent({
  props: {
    element: {
      type: ElementWrapper,
      required: true,
    },
    fieldId: {
      type: String,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
    errorStackSize: {
      type: Number,
      required: true,
    },
  },
  components: {
    AddItemButton,
    DynamicResolver,
    SystemTypeRenderer,
    IndentedEntry,
    ElementFieldList,
    EditItemButton,
    ErrorsBadge,
  },

  computed: {
    fieldsToAdd(): AddableFields {
      return this.element.getAvailableFieldsToAdd();
    },

    renderAsBox(): boolean {
      return this.renderingOptions.renderAsBox && this.depth === 0;
    },

    childBaseDepth(): number {
      if (!this.renderAsBox && this.depth === 0) {
        return this.depth + 1;
      }
      return this.depth;
    },

    sliceName(): string | undefined {
      return getSliceName(this.element.$type);
    },

    showErrors(): boolean {
      if (this.expanded) {
        return this.element.localErrors.length > 0;
      } else {
        return this.element.combinedErrors.length > 0;
      }
    },

    readonly(): boolean {
      return config.appConfig.readOnlyMode;
    },
  },

  watch: {
    /**
     * Watches if we can actually expand, and if not, automatically
     * un-expands this entry since it won't be possible via the UI anymore
     */
    "renderingOptions.expandable"(value: boolean) {
      if (!value) {
        this.expanded = false;
      }
    },
  },

  methods: {
    /**
     * Adds a new field to the element, and also expands it if possible
     */
    addNewField(key: string) {
      this.element.addNewField(key);
    },

    /**
     * Deletes this element
     */
    deleteValue() {
      this.element.deleteValue();
    },
  },

  setup(props, context) {
    // Required for vue3
    const elementReference = ref(null);

    // Computed
    const expanded = ref(false);
    const renderingOptions = computed(() =>
      determineRenderingOptions(props.element, props.depth)
    );

    // Highlighting
    const highlighting = useErrorHighlighting(props, {
      elementReference,
    });

    const updateHighlighting = ({
      containsError,
      isError,
    }: {
      containsError: boolean | null;
      isError: boolean | null;
    }) => {
      if (isError) {
        highlighting.highlight();
      } else if (containsError) {
        if (renderingOptions.value.expandable) {
          expanded.value = true;
        } else {
          highlighting.highlight();
        }
      }
    };

    // Auto-expand and highlight
    watch(highlighting.errorHighlights, (value) => updateHighlighting(value));

    onMounted(() => {
      if (renderingOptions.value.expanded) {
        expanded.value = true;
      }
      updateHighlighting(highlighting.errorHighlights.value);
    });

    // Errors
    const elementErrors = useElementErrors(props, {
      localOnlyRef: computed(() => expanded.value),
    });

    return {
      elementReference,

      SlicedElementDefinition,

      expanded,
      renderingOptions,

      ...elementErrors,
      ...highlighting,
    };
  },
});
