import {
  ArrayWrapper,
  BaseWrapper,
  ChoiceWrapper,
  ElementWrapper,
  isNullOrUndefined,
  PrimitiveDefinition,
  PrimitiveWrapper,
} from "@/lib-on-fhir";

export type RenderingOptions = {
  renderAsBox: boolean;
  expanded: boolean;
  expandable: boolean;

  extraIcons?: { badge: string; tooltip: string }[];

  summaryElement?: BaseWrapper;
};

type ResolverPayload = {
  element: ElementWrapper;
  depth: number;
};

type ResolverResult = {
  summaryText?: string;
  summaryElement?: BaseWrapper;
};

const FIRST_ITEM = Symbol("first");

const CHIPS = {
  extension: "extension",
  binding: "binding",
  fixed: "fixed",
  pattern: "pattern",
  defaultValue: "default",
};

type PathAccess = (string | typeof FIRST_ITEM)[];

function resolvePath(
  element: BaseWrapper,
  path: PathAccess
): BaseWrapper | undefined {
  if (path.length === 0 || !element) {
    return element;
  }

  const next = path[0];
  const remaining = path.slice(1);

  if (next === FIRST_ITEM) {
    if (element instanceof ArrayWrapper && element.items.length === 1) {
      return resolvePath(element.items[0], remaining);
    }
  } else {
    if (element instanceof ElementWrapper) {
      return resolvePath(element.fields[next], remaining);
    }
  }
}

const customResolvers: Record<
  string,
  (payload: ResolverPayload) => ResolverResult
> = {
  Identifier({ element }: ResolverPayload) {
    return {
      summaryElement: resolvePath(element, ["value", FIRST_ITEM, "value"]),
    };
  },

  Coding({ element }: ResolverPayload) {
    return {
      summaryElement: resolvePath(element, ["code", FIRST_ITEM, "value"]),
    };
  },

  Reference({ element }: ResolverPayload) {
    return {
      summaryElement:
        resolvePath(element, ["display", FIRST_ITEM, "value"]) ||
        resolvePath(element, ["reference", FIRST_ITEM, "value"]) ||
        resolvePath(element, [
          "identifier",
          FIRST_ITEM,
          "value",
          FIRST_ITEM,
          "value",
        ]),
    };
  },

  CodeableConcept({ element }: ResolverPayload) {
    return {
      summaryElement: resolvePath(element, [
        "coding",
        FIRST_ITEM,
        "code",
        FIRST_ITEM,
        "value",
      ]),
    };
  },

  Extension({ element }: ResolverPayload) {
    const choiceWrapper = element.fields.value;
    if (choiceWrapper instanceof ChoiceWrapper) {
      if (choiceWrapper.value instanceof ArrayWrapper) {
        const resolved = resolvePath(choiceWrapper.value, [FIRST_ITEM]);
        if (resolved instanceof PrimitiveWrapper) {
          return {
            summaryElement: resolvePath(resolved, ["value"]),
          };
        }
      }
    }

    // if (resolved instanceof PrimitiveWrapper) {
    //   return {
    //     summaryElement: resolvePath(resolved, [FIRST_ITEM, "value"]),
    //   };
    // }
    return {};
  },
};

export function determineRenderingOptions(
  element: ElementWrapper,
  depth: number
): RenderingOptions {
  const customResolver = customResolvers[element.$type.typeName];
  let customResolved = {};
  if (customResolver) {
    customResolved = customResolver({ element, depth });
  }

  if (element.$type instanceof PrimitiveDefinition) {
    let extraIcons: RenderingOptions["extraIcons"] = [];

    if (element.fields.extension) {
      extraIcons.push({
        badge: CHIPS.extension,
        tooltip: "This field has an extension",
      });
    }
    if (element.binding) {
      extraIcons.push({
        badge: CHIPS.binding,
        tooltip:
          "This field is bound to the valueset " +
          element.binding.valueSet +
          " with strength " +
          element.binding.strength,
      });
    }

    if (!isNullOrUndefined(element.fixedValue?.value)) {
      extraIcons.push({
        badge: CHIPS.fixed,
        tooltip:
          "The profile defines a fixed value of '" +
          element.fixedValue?.value +
          "' for this field",
      });
    }

    if (!isNullOrUndefined(element.defaultValue?.value)) {
      extraIcons.push({
        badge: CHIPS.fixed,
        tooltip:
          "The profile defines a default value of '" +
          element.defaultValue?.value +
          "' for this field",
      });
    }

    if (!isNullOrUndefined(element.pattern?.value)) {
      extraIcons.push({
        badge: CHIPS.fixed,
        tooltip:
          "The profile defines a pattern of '" +
          element.pattern?.value +
          "' for this field",
      });
    }

    // primitives
    return {
      renderAsBox: false,
      expanded: false,
      expandable: Boolean(element.fields.id || element.fields.extension),
      summaryElement: element.fields.value,
      extraIcons,
      ...customResolved,
    };
  } else {
    // complex
    let extraIcons: RenderingOptions["extraIcons"] = [];

    if (element.fields.extension) {
      extraIcons.push({
        badge: CHIPS.extension,
        tooltip: "This field has an extension",
      });
    }

    return {
      renderAsBox: true,
      expanded: depth < 1,
      expandable: true,
      extraIcons,
      ...customResolved,
    };
  }
}
